export const convertToFormData = (jsonData) => {
  const formData = new FormData()

  appendObject(formData, jsonData)

  return formData
}

const appendObject = (formData, jsonData, prefix) => {
  Object.keys(jsonData).forEach((key) => {
    const value = jsonData[key]
    const name = prefix ? `${prefix}[${key}]` : key

    if (value === undefined) return
    if (value === null) {
      formData.append(name, '')
    } else if (value instanceof File || value instanceof Blob) {
      formData.append(name, value)
    } else if (Array.isArray(value)) {
      appendArray(formData, value, name)
    } else if (typeof value === 'object' && value !== null) {
      appendObject(formData, value, name)
    } else {
      formData.append(name, value)
    }
  })
}

const appendArray = (formData, arrayValue, prefix) => {
  arrayValue.forEach((singleValue) => {
    const name = `${prefix}[]`

    if (typeof singleValue === 'object' && singleValue !== null) {
      appendObject(formData, singleValue, name)
    } else if (typeof singleValue === Array) {
      appendArray(formData, singleValue, name)
    } else {
      formData.append(name, singleValue)
    }
  })
}
